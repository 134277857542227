import { SelectElement } from '../ui-kit/components/select/select.component';

export enum SexEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  PRENATAL = 'PRENATAL',
}

export const sexOptions: SelectElement<SexEnum>[] = [
  {
    value: SexEnum.MALE,
    label: $localize`Homme`,
  },
  {
    value: SexEnum.FEMALE,
    label: $localize`Femme`,
  },
];

export const getSexEnumLabel = (value: SexEnum): string => {
  switch (value) {
    case SexEnum.MALE:
      return $localize`Homme`;
    case SexEnum.FEMALE:
      return $localize`Femme`;
    case SexEnum.PRENATAL:
      return $localize`Prénatal`;
    default:
      return '';
  }
};

export const getAnimalSexEnumLabel = (value: Omit<SexEnum, SexEnum.PRENATAL>): string => {
  switch (value) {
    case SexEnum.MALE:
      return $localize`Mâle`;
    case SexEnum.FEMALE:
      return $localize`Femelle`;
    default:
      return '';
  }
};
