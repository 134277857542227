import { ComponentType } from '@angular/cdk/portal';

import { BaseIconComponent } from '../ui-kit/icons/base-icon.component';
import { CarIconComponent } from '../ui-kit/icons/car-icon.component';
import { CartwheelIconComponent } from '../ui-kit/icons/cartwheel-icon.component';
import { DoctorIconComponent } from '../ui-kit/icons/doctor-icon.component';
import { DogIconComponent } from '../ui-kit/icons/dog-icon.component';
import { HealthIconComponent } from '../ui-kit/icons/health-icon.component';
import { MinimalistHouseIconComponent } from '../ui-kit/icons/house-minimalist-icon.component';
import { SimpleHouseIconComponent } from '../ui-kit/icons/house-simple-icon.component';
import { JewelryIconComponent } from '../ui-kit/icons/jewelry-icon.component';
import { KeyIconComponent } from '../ui-kit/icons/key-icon.component';
import { MergeIconComponent } from '../ui-kit/icons/merge-icon.component';
import { MoneyBillIconComponent } from '../ui-kit/icons/money-bill-icon.component';
import { PlaneIconComponent } from '../ui-kit/icons/plane-icon.component';

export enum InsuranceTypeEnum {
  HEALTH = 'HEALTH',
  TRAVEL = 'TRAVEL',
  BUILDING = 'BUILDING',
  RC_HOUSEHOLD = 'RC_HOUSEHOLD',
  LEGAL_PROTECTION = 'LEGAL_PROTECTION',
  BUSINESS_LEGAL_PROTECTION = 'BUSINESS_LEGAL_PROTECTION',
  BUSINESS_COMPANY_THINGS = 'BUSINESS_COMPANY_THINGS',
  BUSINESS_INSURANCE_OFFER = 'BUSINESS_INSURANCE_OFFER',
  MOTOR_VEHICLE = 'MOTOR_VEHICLE',
  ANIMAL = 'ANIMAL',
  HOUSING_RENTAL = 'HOUSING_RENTAL',
  QUOTATION_REQUEST = 'QUOTATION_REQUEST',
  CONSTRUCTION = 'CONSTRUCTION',
  THIRD_PILLAR = 'THIRD_PILLAR',
  COMPLEMENTARY_HEALTH = 'COMPLEMENTARY_HEALTH',
  BENEFITS_ACCOUNT = 'BENEFITS_ACCOUNT',
  CENTRALISATION = 'CENTRALISATION',
  CANCELLATION = 'CANCELLATION',
  VALUABLE_OBJECTS = 'VALUABLE_OBJECTS',
}

export const getInsuranceTypeLabel = (type: InsuranceTypeEnum): string => {
  switch (type) {
    case InsuranceTypeEnum.HEALTH:
      return $localize`Caisse maladie`;
    case InsuranceTypeEnum.TRAVEL:
      return $localize`Voyage`;
    case InsuranceTypeEnum.BUILDING:
      return $localize`Bâtiment`;
    case InsuranceTypeEnum.RC_HOUSEHOLD:
      return $localize`RC ménage`;
    case InsuranceTypeEnum.LEGAL_PROTECTION:
      return $localize`Protection juridique`;
    case InsuranceTypeEnum.BUSINESS_LEGAL_PROTECTION:
      return $localize`Protection juridique entreprise`;
    case InsuranceTypeEnum.BUSINESS_COMPANY_THINGS:
      return $localize`Choses de l'entreprise`;
    case InsuranceTypeEnum.BUSINESS_INSURANCE_OFFER:
      return $localize`Offre d'assurance entreprise`;
    case InsuranceTypeEnum.MOTOR_VEHICLE:
      return $localize`Véhicule à moteur`;
    case InsuranceTypeEnum.ANIMAL:
      return $localize`Animal`;
    case InsuranceTypeEnum.HOUSING_RENTAL:
      return $localize`Garantie de loyer`;
    case InsuranceTypeEnum.QUOTATION_REQUEST:
      return $localize`Demande de devis`;
    case InsuranceTypeEnum.CONSTRUCTION:
      return $localize`Construction`;
    case InsuranceTypeEnum.THIRD_PILLAR:
      return $localize`3ème pilier`;
    case InsuranceTypeEnum.COMPLEMENTARY_HEALTH:
      return $localize`Santé complémentaire`;
    case InsuranceTypeEnum.BENEFITS_ACCOUNT:
      return $localize`Compte de prestations`;
    case InsuranceTypeEnum.CENTRALISATION:
      return $localize`Centralisation`;
    case InsuranceTypeEnum.CANCELLATION:
      return $localize`Résiliation`;
    case InsuranceTypeEnum.VALUABLE_OBJECTS:
      return $localize`Objets de valeur`;
    default:
      return '';
  }
};

export const getInsuranceTypeIcon = (type: InsuranceTypeEnum): ComponentType<BaseIconComponent> => {
  switch (type) {
    case InsuranceTypeEnum.HEALTH:
      return DoctorIconComponent;
    case InsuranceTypeEnum.TRAVEL:
      return PlaneIconComponent;
    case InsuranceTypeEnum.BUILDING:
      return SimpleHouseIconComponent;
    case InsuranceTypeEnum.RC_HOUSEHOLD:
      return CartwheelIconComponent;
    case InsuranceTypeEnum.LEGAL_PROTECTION:
      return KeyIconComponent;
    case InsuranceTypeEnum.BUSINESS_LEGAL_PROTECTION:
      return null;
    case InsuranceTypeEnum.BUSINESS_COMPANY_THINGS:
      return null;
    case InsuranceTypeEnum.BUSINESS_INSURANCE_OFFER:
      return null;
    case InsuranceTypeEnum.MOTOR_VEHICLE:
      return CarIconComponent;
    case InsuranceTypeEnum.ANIMAL:
      return DogIconComponent;
    case InsuranceTypeEnum.HOUSING_RENTAL:
      return MoneyBillIconComponent;
    case InsuranceTypeEnum.QUOTATION_REQUEST:
      return null;
    case InsuranceTypeEnum.CONSTRUCTION:
      return MinimalistHouseIconComponent;
    case InsuranceTypeEnum.THIRD_PILLAR:
      return MoneyBillIconComponent;
    case InsuranceTypeEnum.COMPLEMENTARY_HEALTH:
      return HealthIconComponent;
    case InsuranceTypeEnum.BENEFITS_ACCOUNT:
      return null;
    case InsuranceTypeEnum.CENTRALISATION:
      return MergeIconComponent;
    case InsuranceTypeEnum.VALUABLE_OBJECTS:
      return JewelryIconComponent;
    default:
      return null;
  }
};
