export enum CatType {
  DOMESTIC = 'DOMESTIC',
  EXTERIOR = 'EXTERIOR',
}

export const getCatTypeLabel = (value: CatType): string => {
  switch (value) {
    case CatType.DOMESTIC:
      return $localize`Chat domestique`;
    case CatType.EXTERIOR:
      return $localize`Chat extérieur`;
    default:
      return '';
  }
};
