export const environment = {
  name: 'staging',
  production: true,
  staging: true,
  DATE_FORMAT: 'dd.MM.yyyy',
  BASE_URL: 'https://staging.api.ltytech.ch/',
  USERAPIENDPOINT: 'https://staging.api.ltytech.ch/api/ims/v1/',
  COMMUNICATORAPIURL: 'https://staging.api.ltytech.ch/api/communicator/v1/',
  DOCUMENTAPIURL: 'https://staging.api.ltytech.ch/api/document-store/v1/',
  INSURANCEAPIURL: 'https://staging.api.ltytech.ch/api/insurance/v1/',
  CATALOGUEAPIURL: 'https:/staging.api.ltytech.ch/api/catalogue/v1/',
  VERSION: '1.0.2',

  tokenValidityTimeMinutes: 60,
  SHOWCASE_WEBSITE_URL: 'https://www.lty.ch/',
  CALENDLY_URL: 'https://calendly.com/v-thetaz-lty/lty?hide_gdpr_banner=1',
  // Referrer for which the top bar will make a back() call instead of redirecting to the dashboard
  BACKABLE_REFERRER: 'lty.ch',
};
